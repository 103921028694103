export  const homeObjOne = {
    id: 'expertise',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    script: false,
    big: false,
    topLine: '',
    headLine2:'',
    headLine: 'Our Expertise',
    description: 'CEO Inc. is an award-winning, full-service, direct-hire search firm serving customers for 30 years. We offer staffing services and talent acquisition with complete confidentiality and the highest professional standards in the industry.',
    imgStart: false,
    img: require('../../images/team.png'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,
    short: true,
    slides1: true,
    isimg: false
}
export  const homeObjTwo = {
    id: 'spotlight',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    script: true,
    big: true,
    topLine: '',
    headLine: 'spotlight',
    description: '"Delivering top talent and staffing solutions nationwide for 30 years."',
    imgStart: true,
    img: require('../../images/team.png'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,
    short:false,
    slides1: false,
    isimg: true
}

