export const SliderData = [
    {
      image:
      require('../../images/1x/engineering.png') 
    },
    {
      image:
      require('../../images/1x/hr.png') 
    },
    {
      image:
      require('../../images/1x/sales.png')   
    },
    {
      image:
      require('../../images/1x/finance.png')  
    },
    {
      image:
      require('../../images/1x/product.png')   
    }
  ];
export const SecondSliderData = [
    {
      image:
      require('../../images/achievements.png')   
    }
  ];